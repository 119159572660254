var winWidth = 0;
var getSubNav = function(){
	winWidth = $(window).width();
	if(winWidth <= 768){

	}
};

var isMap = false;

// ----------------------------------
// GMAP
// ----------------------------------
// When the window has finished loading create our google map below
    // google.maps.event.addDomListener(window, 'load', init);

    function initMap() {

    	if( $("#map").length === 0 ) return false;

    	isMap = true;

    	var lat = $("input[name=lat]").val();
    	var lng = $("input[name=lng]").val();
    	var zoom = parseInt($("input[name=zoom]").val());

        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: zoom,

            // The latitude and longitude to center the map (always required)
            // center: new google.maps.LatLng(48.87541849999999, 2.296299999999974),
            center: new google.maps.LatLng(lat, lng),

            // How you would like to style the map. 
            // This is where you would paste any style found on Snazzy Maps.
            
            styles: [	{featureType:"administrative",elementType:"all",stylers:[{visibility:"on"},{saturation:-100},{lightness:20}]},	{featureType:"road",elementType:"all",stylers:[{visibility:"on"},{saturation:-100},{lightness:40}]},	{featureType:"water",elementType:"all",stylers:[{visibility:"on"},{saturation:-10},{lightness:30}]},	{featureType:"landscape.man_made",elementType:"all",stylers:[{visibility:"simplified"},{saturation:-60},{lightness:10}]},	{featureType:"landscape.natural",elementType:"all",stylers:[{visibility:"simplified"},{saturation:-60},{lightness:60}]},	{featureType:"poi",elementType:"all",stylers:[{visibility:"off"},{saturation:-100},{lightness:60}]},	{featureType:"transit",elementType:"all",stylers:[{visibility:"off"},{saturation:-100},{lightness:60}]}]
        };

        // Get the HTML DOM element that will contain your map 
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using out element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

    	// var image = 'img/marker.png';

    	var image = new google.maps.MarkerImage('img/marker.png', null, null, null, new google.maps.Size(76,60));

		var myLatLng = new google.maps.LatLng(lat, lng);
		var myMarker = new google.maps.Marker({
	    	position: myLatLng,
	    	map: map,
	    	icon: image
	  	});
    }
// ----------------------------------
// END GMAP
// ----------------------------------

function formatNumber(nbr){
	var nombre = ''+nbr;
	var retour = '';
	var count = 0;
	for(var i=nombre.length-1 ; i>=0 ; i--){
		if(count!==0 && count % 3 === 0)
			retour = nombre[i]+' '+retour ;
		else
			retour = nombre[i]+retour ;
		count++;
	}
	return retour;
}

fotoramaDefaults = {
	width: '100%',
	ratio: '902/542',
	allowfullscreen: false,
	nav: 'thumbs',
	loop : true
};

// jQuery stuff
$(document).ready(function() {

	$("a.toggle").click(function(e){
		e.preventDefault();
		$(this).next('ul').slideToggle();
	});

	$("a.js-google-map").click(function(e){
		e.preventDefault();
		$("#map").toggle();
		if( !isMap )  initMap();
	});

	$(".links-search a").fancybox({
		// maxWidth:    350,
		autoSize:    true,
		closeClick:  false,
		openEffect:  'none',
		closeEffect: 'none',
		margin:      0,
		padding:     0,
		helpers: {
			overlay : {
				locked : false
			}
		},
		afterShow: function(){
      $("#FormSearch li.optin label").click(function(){
        $("#FormSearch li.optin label span").toggleClass("icon-checkbox-checked");
        $("#FormSearch button[type='submit']").attr("disabled", !$("#FormSearch li.optin label span").hasClass("icon-checkbox-checked"));
      });

			$("#FormSearch").validationEngine({
				promptPosition : "centerRight:-135,0", 
				scroll: false
			});
			$("#FormSearch").submit(function(){
				if($("#FormSearch").validationEngine('validate')){
					var data = $(this).serialize() + '&act=recherche';
					var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

					$.ajax({
						type    : "POST",
						data    : data,
						url     : "/contact/",
						success : function(retour) {
							div.empty().append(retour);
						}
					});
				}
				return false;
			});
		}
	});
	
	$("#nav-social li a").hover(function(){
		$("#nav-social li a").not($(this)).addClass('not-current');
	}, function(){
		$("#nav-social li a").not($(this)).removeClass('not-current');		
	});

	// Initialisation de la recherche de bien
	iniSearch();

	// Formulaire de contact
	$(".contact form ul li:first label").click(function(){
		$(".contact form ul li:first label span").removeClass('icon-radio-checked');
		$(this).find('span').toggleClass('icon-radio-checked');
	});

  $(".contact form ul li.optin label").click(function(){
    $(".contact form ul li.optin label span").toggleClass("icon-checkbox-checked");
    $(".contact form button[type='submit']").attr("disabled", !$(".contact form ul li.optin label span").hasClass("icon-checkbox-checked"));
  });


	$("#FormContact").validationEngine({
		promptPosition : "centerRight:-135,0", 
		scroll: false
	});

	$("#FormContact").submit(function(){
		
		var url = window.location.href;

		if($("#FormContact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				// url     : "/contact/",
				url     : url,
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	$('.ad a').matchHeight();

});
