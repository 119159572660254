
var getValue = function(el, value){

	if( el.attr('name') == 'arrondissement'){

		switch( parseInt(value) ){
			case 0:
				val = "Tous";
				break;
			case 1:
				val = value+"<sup>er</sup>";
				break;
			default:
				val = value+"ème";
				break;
		}
		val = val+" arr.";

	}

	else if( el.attr('name') == 'etage'){

		switch( parseInt(value) ){
			case 0:
				val = "Tout étage";
				break;
			case 1:
				val = value+"<sup>er</sup> étage min.";
				break;
			default:
				val = value+"ème étage min.";
				break;
		}
		val = val;

	}

	else if( el.attr('name') == 'piece'){

		switch( parseInt(value) ){
			case 0:
				val = "Nombre de pièce indifférent";
				break;
			default:
				val = value+" pièce(s) min.";
				break;
		}
		val = val;

	}


	else{
		val = formatNumber( value );
	}

	return val;
}

var min   = 0;
var max   = 1;
var step  = 1;
var start = 0;
var end   = 1;

var getSliderPriceValue = function(act){
	var categorie = $('input[name="categorie"]:checked').val();
	var range = $("#search_prix").val().split(',');
	if( categorie == "AnnonceLocation" ){
		min  = 0;
		max  = 8000;
		step = 500;
	}else{
		min  = 0;
		max  = 6000000;
		step = 10000;
	}
	start = ( act == "init" ) ? range[0] : min;
	end   = ( act == "init" ) ? range[1] : max;
}

var updateSliderPrice = function(act){
	getSliderPriceValue(act);
	document.getElementById('slider_price').noUiSlider.updateOptions({
		range: {
			'min': min,
			'max': max
		},
		step: step,
		margin: step,
		start: [start, end]
	});
}

var iniSlider = function(){

	var slider_price = document.getElementById('slider_price');
	if(slider_price) {
		getSliderPriceValue('init');
		noUiSlider.create(slider_price, {
			tooltips: [ wNumb({ decimals: 0, postfix:" €", thousand: ' ' }), wNumb({ decimals: 0, postfix:" €", thousand: ' ' }) ],
			range: {
				'min': min,
				'max': max
			},
			step: step,
			margin: step,
			start: [start, end],
			connect: true
		});

		slider_price.noUiSlider.on('change', function(values){
			$("#search_prix").val(values);
		});
		slider_price.noUiSlider.on('update', function(values){
			$("#search_prix").val(values);
		});

		$('input[name="categorie"]').unbind('change').change(function(){
			updateSliderPrice('reset');
		});

		var slider_meter = document.getElementById('slider_meter');
		var slider_meter_value = $("#search_surface").val().split(',');
		noUiSlider.create(slider_meter, {
			start: [ slider_meter_value[0], slider_meter_value[1] ],
			connect: true,
			step: 10,
			margin: 10,
			tooltips: [ wNumb({ decimals: 0, postfix:" m²" }), wNumb({ decimals: 0, postfix:" m²" }) ],
			range: {
				'min': 0,
				'max': 1000
			}
		});

		slider_meter.noUiSlider.on('change', function(values){
			$("#search_surface").val(values);
		});

		var slider_capacity = document.getElementById('slider_capacity');
		noUiSlider.create(slider_capacity, {
			start: $("#search_piece").val(),
			step: 1,
			tooltips: [ false ],
			range: {
				'min': 0,
				'max': 5
			}
		});

		var capacityFormat = function(value){
			if( value == 0 ){
				return 'Nombre de pièce indifférent';
			}else{
				return wNumb({
					decimals:0
				}).from(value)+" pièce(s) min.";
			}
		}

		slider_capacity.noUiSlider.on('change', function(values){
			$("#search_piece").val( wNumb({ decimals:0 }).from(values[0]) );
		});
		slider_capacity.noUiSlider.on('slide', function(values){
			$("#slider_capacity").next('p').html(capacityFormat(values[0]));
		});
		slider_capacity.noUiSlider.on('update', function(values){
			$("#slider_capacity").next('p').html(capacityFormat(values[0]));
		});

		var slider_stairs = document.getElementById('slider_stairs');
		noUiSlider.create(slider_stairs, {
			start: $("#search_etage").val(),
			step: 1,
			tooltips: [ false ],
			range: {
				'min': 0,
				'max': 10
			}
		});

		var stairsFormat = function(value){
			if( value == 0 ){
				return 'Tout étage';
			}else if( value == 1 ){
				return wNumb({
					decimals:0
				}).from(value)+"<sup>er</sup> étage min.";
			}else{
				return wNumb({
					decimals:0
				}).from(value)+"<sup>ème</sup> étage min.";
			}
		}

		slider_stairs.noUiSlider.on('change', function(values){
			$("#search_etage").val( wNumb({ decimals:0 }).from(values[0]) );
		});
		slider_stairs.noUiSlider.on('slide', function(values){
			$("#slider_stairs").next('p').html(stairsFormat(values[0]));
		});
		slider_stairs.noUiSlider.on('update', function(values){
			$("#slider_stairs").next('p').html(stairsFormat(values[0]));
		});

		$('.form-recherche').submit(function(){

			if( !$('#categorie_vente').is(':checked') && !$('#categorie_location').is(':checked') ){
				$error = $('.form-recherche').find('.form-recherche-error');
				$error = $error.html('Merci de sélectionner votre type de bien rechercher');
				$error.addClass('form-recherche-error--highlight');
				// alert(' Merci de sélectionner votre type de bien ');
				return false;
			}
		});
	}
}

var iniOption = function(){

	// refreshSliderPrice();
	
	$("ul.icons li a").click(function(e){
		e.preventDefault();
		$(this).toggleClass('checked');
		input = $(this).next('input');
		input.trigger('click');
	});

	$("ul.district li label").click(function(e){
		if( $(this).next('input').attr('type') == "radio" ){
			$(this).closest('ul').children('li').children('label').children('span').removeClass("icon-checkbox-checked");
		}
		$(this).find('span').toggleClass("icon-checkbox-checked");
	});

	$(".toggle--check-all").click(function(e){
		e.preventDefault();
		$(".district:not(.categorie)").find("input:not(:checked)").each(function(){
			$(this).attr('checked', true).prev('label').find('span').addClass('icon-checkbox-checked');
		});
	});

	$(".toggle--check-none").click(function(e){
		e.preventDefault();
		$(".district:not(.categorie)").find("input").each(function(){
			$(this).attr('checked', false).prev('label').find('span').removeClass('icon-checkbox-checked');
		});
	});

	// $("ul.district li a").click(function(e){
	// 	e.preventDefault();

	// 	$(this).toggleClass('checked');

	// 	InputList = $(this).next('input');

	// 	if( $(this).hasClass('all') ){
			
	// 		$("ul.district li a:not('.none')").addClass('checked');
	// 		InputList = $("ul.district li input").not(":checked");
			
	// 	}else if( $(this).hasClass('none') ){

	// 		$("ul.district li a:not('.all')").removeClass('checked');
	// 		InputList = $("ul.district li input:checked");

	// 	}else{

	// 		$("ul.district li a.all span").removeClass('icon-radio-checked');

	// 	}
		
	// 	InputList.trigger('click');

	// });

}

var iniSearch = function(){

	// $("#city").select2({
	// 	placeholder: "Choisissez une ville"
	// });

	iniSlider();
	iniOption();

}